/* eslint-disable no-unused-vars */
export enum EVideoFilters {
  HOME = "HOME",
  TRENDING = "TRENDING",
  RECOMMENDATIONS = "RECOMMENDATIONS",
  SUBSCRIPTIONS = "SUBSCRIPTIONS",
  LATEST = "LATEST",
  PAIDSUBSCRIPTIONS = "PAIDSUBSCRIPTIONS",
  LISTS = "LISTS",
}
