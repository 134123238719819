import { configureStore, createAction } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authReducer } from "./auth/auth";
import { commentReducer } from "./comment/comment";
import { encryptTransform } from "redux-persist-transform-encrypt";

const reducer = combineReducers({
  auth: authReducer,
  comment: commentReducer,
});
export const resetAction = createAction("reset");

const resettableReducer = (state: any, action: any) => {
  if (resetAction.match(action)) {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};
const persistConfig = {
  transforms: [
    encryptTransform({
      secretKey: "my-super-secret-key",
      onError: function () {
        console.error(
          "Error: Some Error Occured while encrypting the redux persist state !!!"
        );
      },
    }),
  ],
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth", "i18n"],
};

const persistedReducer = persistReducer(persistConfig, resettableReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
