import React, { useState } from "react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
//@ts-ignore
import Autosuggest from "react-autosuggest";

interface IProps {
  toggleClassHandler: any;
  yourVideos: boolean;
}

export const SearchBarComponent: React.FC<IProps> = (props) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState<any>([]);
  const router = useRouter();
  const { t } = useTranslation("common");

  const getSuggestions = async (value: string) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength === 0) setSuggestions([]);
    try {
      // let res: any;
      // if (props.yourVideos)
      //   res = await videoService.suggestedMyVideonames(value);
      // else res = await videoService.suggestednames(value);
      // setSuggestions(res.data.videos);
    } catch (error) {
      setSuggestions([]);
    }
  };

  const getSuggestionValue = (suggestion: any) => suggestion.name;

  const cleanSearchQuery = async () => {
    setQuery('');
    await router.push(`/`);
  }

  const handleChange = (e: any) => {
    setQuery(e.target.value);
  };

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    router.push(`/?q=${query}`);
    // router.reload()
  };

  const onSuggestionSelected = (e: any) => {
    setSuggestions([]);
    setQuery("");
    e.preventDefault();
    // router.push(`/?q=${value.suggestion.name}`);
  };

  const onSuggestionsFetchRequested = (value: any) => {
    getSuggestions(value.value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  return (
    <React.Fragment>
      <div className="at-searcharea">
        <form className="at-searchbox" onSubmit={onSubmitHandler}>
          <button
            type="button"
            className="at-crossbtn"
            onClick={() => props.toggleClassHandler()}
          >
            <i className="fa fa-times"></i>
          </button>
          <div className="at-inputwithbtn">
            <Autosuggest
              suggestions={suggestions}
              onSuggestionSelected={onSuggestionSelected}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={(value: any) => value.name}
              inputProps={{
                placeholder: t("navigation.search"),
                value: query,
                className: "form-control",
                onChange: handleChange,
              }}
            />
            <button type="submit" className={`at-icon-${query ? 'hidden' : 'show'}`}>
              <i className="icon-search"></i>
            </button>
            <button onClick={cleanSearchQuery} type="button" className={`at-icon-${query ? 'show' : 'hidden'}`}>
              <i className="fa fa-times"></i> 
            </button>
          </div>
        </form>
      </div>
      {/* <button
        type="button"
        className="at-searchbtn"
        onClick={() => props.toggleClassHandler()}
      >
        <i className="icon-search"></i>
      </button> */}
      <style jsx global>{`
        .at-searchbtn {
          background: linear-gradient(to right, #dcccff, #bee9fd);
          color: white;
          height: 40px;

          align-items: center;
          font-size: 17px;
          --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -1px rgba(0, 0, 0, 0.06);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
            var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          text-shadow: 1px 1px 1px #8080803b;
          padding: 0px 18px;
          margin-right: 10px;

          @media screen and (max-width: 1110px) {
            display: flex;
          }
        }
        .at-searchbox {
          box-shadow: none !important;

          @media screen and (max-width: 1110px) {
            .at-inputwithbtn {
              display: none;
            }
          }

          .form-control {
            color: #E7F3F3;
            font-family: var(--primary-font);
            font-size: 14px;
            font-weight: 400;
            height: 40px !important;

            &::placeholder {
              color: #a9aeb7 !important;
              font-family: var(--primary-font) !important;
            }
          }

          button {
            i {
              color: #a9aeb7 !important;
            }
          }
        }
        .at-searcharea {
          margin: 0px;
          margin-left: 25px;
        }

        .at-navarea {
          justify-content: space-between;

          @media screen and (max-width: 1110px) {
            justify-content: flex-end;
          }
        }

        .at-icon {
          &-show {
            display: block;
            cursor: pointer;
          }
          
          &-hidden {
            display: none;
          }
        }
      `}</style>
    </React.Fragment>
  );
};
