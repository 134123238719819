import { authService } from '@services/auth';
import { useContext } from 'react';
import { authContext, IAuth } from '@context/auth.context';
import { useRouter } from 'next/router';

import useLocalStorage from './useLocalStorage';

export const useAuth = () => {
  return useContext(authContext);
};

export function useProvideAuth(): IAuth {
  const router = useRouter();
  const [user, setUser] = useLocalStorage<any>('user', null);
  const [token, setToken] = useLocalStorage<any>('token', null);

  const refresh = async () => {
    try {
      const { data } = await authService.getCurrentUser(new Date().getTime());
      setUser(data);
    } catch (err:any) {
      console.warn("useAuth.refresh ::", err?.message)
    }
  };

  const getUserFromUserStorage = () => {
    return localStorage.getItem('user');
  };

  const refreshProfile = async (newData: any) => {
    try {
      const data = JSON.parse(getUserFromUserStorage() || '{}');
      data.categories = newData.categories;
      setUser(data);
    } catch (err:any) {
      console.warn("useAuth.refreshProfile ::", err?.message)
    }
  };

  const refreshStarduts = async (amount: number) => {
    try {
      const data = JSON.parse(getUserFromUserStorage() || '{}');
      data.stardusts = data.stardusts - amount;
      setUser(data);
    } catch (err:any) {
      console.warn("useAuth.refreshStarduts ::", err?.message)
    }
  };

  const login = (access_token: string, newUser: any) => {
    setToken(access_token);
    setUser(newUser);
  };

  const logout = async () => {
    await router.push("/");
    setToken(null);
    setUser(null);
    window.location.reload();
  };

  const softLogout = () => {
    setToken(null);
    setUser(null);
  }

  return {
    token,
    user,
    login,
    getUserFromUserStorage,
    refreshStarduts,
    refreshProfile,
    logout,
    softLogout,
    refresh
  };
}
