import config from "@config/index";

export const getThumbnail = (url: string = "") => {
  if (url.startsWith("http")) {
    return url;
  }
  return `${config.BASE_URL}/thumbnail/${url}`;
};

export const getProfilePicture = (url: string = "") => {
  const placeholder = '/assets/images/user-img/sm-08.jpg';
  if (!url) return placeholder;
  if (url.includes("s4.stardeos.com")) {
    return url;
  } else if (url.startsWith("s4")) {
    return `http://${url}`;
  } else if (url.includes("p2p-fe-prod")) {
    return url;
  }
  return `${config.BASE_URL}/thumbnail/${url}`;
  /* return `${config.BASE_URL}/thumbnail/${url}?` + Math.random(); */ // Downlaod again the Profile Picture
};
