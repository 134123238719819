import axios, { CancelTokenStatic, CancelTokenSource } from "axios";
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from "axios-extensions";
import { default as base64 } from 'base-64';

import Config from "@config/index";

export class HttpService {
  CancelToken: CancelTokenStatic;
  source: CancelTokenSource;
  instance;

  constructor() {
    this.CancelToken = axios.CancelToken;
    this.source = this.CancelToken.source();

    this.instance = axios.create({
      baseURL: Config.API_ENDPOINT,
      adapter: throttleAdapterEnhancer(
        // @ts-ignore
        cacheAdapterEnhancer(axios.defaults.adapter)
      ),
    });
  }

  protected getHeaders() {

    //Check If the token still valid

    async function checkToken (){

      try{

        let _window = window

        if (await _window?.localStorage.getItem("token") !== "null" && await _window?.localStorage.getItem("token") !== null) {

          let valid = JSON.parse(base64.decode(String(await _window?.localStorage.getItem("token")?.replaceAll('"', "").split(".")[1])))

          if ((Date.now() + ((valid.exp - valid.iat) * 1000)) < Date.now()) {

            window.localStorage.removeItem("token")
            window.localStorage.removeItem("user")

            window.location.reload()

          }

        }

      }catch{

        

      }

    }

    checkToken()

    return Object.assign(
      {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
      typeof window !== "undefined" && window.localStorage.getItem("token")
        ? {
            Authorization: `Bearer ${String(
              window.localStorage.getItem("token")
            ).replace(/['"]+/g, "")}`,
          }
        : {}
    );
  }

  /**
   * Fetch data from server
   * @param url Endpoint link
   * @return Promise
   */
  protected get = (url: string, params?: any): Promise<any> => {
    this.instance.defaults.headers = this.getHeaders();
    return this.instance.get(url, {
      params,
      cancelToken: this.source.token,
    });
  };

  /**
   * Write data over server
   * @param url Endpoint link
   * @param body Data to send over server
   * @return Promise
   */
  protected post = (url: string, body: any, options = {}): Promise<any> => {
    this.instance.defaults.headers = this.getHeaders();
    return this.instance.post(url, body, {
      ...options,
      cancelToken: this.source.token,
    });
  };

  /**
   * Delete Data From Server
   * @param url Endpoint link
   * @param params Embed as query params
   * @return Promise
   */
  protected delete = (url: string, params?: any, data?: any): Promise<any> => {
    this.instance.defaults.headers = this.getHeaders();
    return this.instance.delete(url, { params, data });
  };

  /**
   * Update data on server
   * @param url Endpoint link
   * @param body Data to send over server
   * @param params Embed as query params
   * @return Promise
   */
  protected put = (url: string, body?: any, params?: any): Promise<any> => {
    this.instance.defaults.headers = this.getHeaders();
    return this.instance.put(url, body, {
      ...params,
      cancelToken: this.source.token,
    });
  };

  /**
   * Update data on server
   * @param url Endpoint link
   * @param body Data to send over server
   * @param params Embed as query params
   * @return Promise
   */
  protected patch = (url: string, body?: any, params?: any): Promise<any> => {
    this.instance.defaults.headers = this.getHeaders();
    return this.instance.patch(url, body, {
      ...params,
      cancelToken: this.source.token,
    });
  };

  protected updateCancelToken() {
    this.source = this.CancelToken.source();
  }

  cancel = () => {
    this.source.cancel("Explicitly cancelled HTTP request");
    this.updateCancelToken();
  };
}
