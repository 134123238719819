import { createContext } from "react";
import { useProvideAuth } from "@hooks/useAuth";
import { LoginRequired } from "@components/error-routes/LoginRequired";
import { CreatorRequired } from "@components/error-routes/CreatorRequired";

export interface IAuth {
  token: string | null | undefined;
  user: any | null | undefined;
  login: Function;
  refreshStarduts: Function;
  refreshProfile: Function;
  getUserFromUserStorage: Function;
  logout: Function;
  softLogout: Function;
  refresh: Function;
}

export const authContext = createContext<IAuth>({
  token: null,
  user: null,
  login: () => {},
  refreshStarduts: () => {},
  refreshProfile: () => {},
  getUserFromUserStorage: () => {},
  logout: () => {},
  softLogout: () => { },
  refresh: () => {},
});

export function AuthProvider({ pageProps, children }: any) {
  const auth = useProvideAuth();
  if (!auth.token && pageProps.loginRequired) {
    // Protected Routes
    return <LoginRequired />;
  }

  if (pageProps.creatorRequired && !auth.user?.roles?.includes("CREATOR")) {
    return <CreatorRequired />;
  }

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
