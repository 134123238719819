/* eslint-disable @next/next/no-css-tags */
import React, { useMemo, useState } from "react";
import Head from "next/head";
import Link from "next/link";
import styles from "./left-menu.layout.module.scss";
import {TrendingComponent} from "@components/banner/trending/trending.component";
import {LatestComponent} from "@components/banner/latest/latest.component";
import { ListComponent } from "@components/banner/lists/list.component";
import { PaidSubscriptionComponent } from "@components/banner/paidsubscription/paidsubscription.component";
import {RecommendedComponent} from "@components/banner/recommended/recommended.component";
import {SubscriptionComponent} from "@components/banner/subscription/subscription.component";
import { NavigationBar } from "@components/nav/nav.component";
import { DOMAIN } from "../constants/domain";
import cx from "classnames";
import StardeosAd from "@components/stardeos-ad/stardeos-ad.component";
import {useAuth} from "@hooks/useAuth";
import {EVideoFilters} from "@enums/videoFilter.enum";

export default function VideoLayout({
  children,
  title,
  description,
  path,
  styleClass,
  image,
  filter,
  setFilter,
  setUserId,
  reload,
  setCategories
}: any) {

  const {user} = useAuth();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isDarked, setIsDarked] = useState<boolean>(true);

  const toggleCollapse = () => {
    setIsCollapsed(prevState => !prevState);
  };

  const toggleDropdown = (newValue: boolean | ((prevState: boolean) => boolean)) => {
    setIsDarked(newValue);
  };

  return useMemo(() => {
    return (
      <div className={styleClass}>
        <Head>
          <title>{`${title} - Stardeos`}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={`${DOMAIN}/${path}`} />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Stardeos" />
          <meta property="og:title" content={`${title} - Stardeos`} />
          <meta property="og:description" content={description} />
          <meta name="twitter:title" content={title} />
          <meta property="og:image" content={image} />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
          <link rel="stylesheet" href="/assets/css/icomoon.css" />
          <meta name="theme-color" content="#000000" />
          <link rel="icon" href="/favicon.ico" />
          <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        </Head>
        <div className="at-wrapper">
          <header className="at-header">
            <NavigationBar toggleCollapse={ toggleCollapse } toggleDropDown={ toggleDropdown }/>
          </header>
          <div className={isDarked ? "stbxcnt" : "stbxcnt darked"}>
            {typeof window !== "undefined" && (
              <aside className={isCollapsed ? "collapsed sdbr" : "sdbr"}>
                <div className={styles["principal-navigation-one"]}>
                  <TrendingComponent
                    active={filter === EVideoFilters.TRENDING}
                    setActive={setFilter}
                    setUserId={setUserId}
                    setCategories={setCategories}
                    reload={reload}
                  />
                  <LatestComponent
                    active={filter === EVideoFilters.LATEST}
                    setActive={setFilter}
                    setUserId={setUserId}
                    setCategories={setCategories}
                    reload={reload}
                  />
                  {user && (
                    <>
                      <SubscriptionComponent
                        active={filter === EVideoFilters.SUBSCRIPTIONS}
                        setActive={setFilter}
                        userId={user._id}
                        setUserId={setUserId}
                        setCategories={setCategories}
                        reload={reload}
                      />
                      <RecommendedComponent
                        active={filter === EVideoFilters.RECOMMENDATIONS}
                        setActive={setFilter}
                        setUserId={setUserId}
                        categories={user.categories}
                        setCategories={setCategories}
                        reload={reload}
                      />
                      <PaidSubscriptionComponent
                        active={filter === EVideoFilters.PAIDSUBSCRIPTIONS}
                        setActive={setFilter}
                        // userId={user._id}
                        setUserId={setUserId}
                        setCategories={setCategories}
                        reload={reload}
                      />
                      <ListComponent
                        active={filter === EVideoFilters.LISTS}
                        setActive={setFilter}
                        // userId={user._id}
                        setUserId={setUserId}
                        setCategories={setCategories}
                        reload={reload}
                      />
                    </>
                  )}
                </div>
                <div className={styles["secondary-navigation-one"]} style={{position: "relative", zIndex: 999, marginBottom: "20px"}}></div>
                <div
                  className={cx(styles["adsense-one"], styles["ad200-200-one"])}
                >
                  <div className={styles["adsense-container-one"]}>
                    <div
                      style={{width: 200, height: 400}}
                    >

                      <StardeosAd
                        adType="left"
                        position="initial"
                        displayText={false}
                      />
                    </div>
                  </div>
                  <div className={styles["stardust-ads-container-one"]}
                       onClick={() => window.location.href = 'http://ads.stardeos.com/'}>

                    <div style={{fontSize: 14, fontWeight: 700}}>
                      ¡Anuncia <b>LO QUE QUIERAS</b>!
                      <br/>A partir de 5€-10,000 visitas.
                      <br/>¡Precios locos de salida!
                      <br/>👇 <b>CLIC AQUÍ</b> 👇
                      <br/><u><b>http://ads.stardeos.com/</b></u>
                    </div>
                  </div>
                </div>
                <div className={styles["secondary-navigation-one"]} style={{position: "relative", zIndex: 999}}>
                  <ul className="sdbr-mn list-unstyled">
                    <li>
                      <Link href={"/trabaja-con-nosotros"}>
                        <a className="dropdown-item">Trabaja con nosotros</a>
                      </Link>
                    </li>
                    <li>
                      <Link href={"/terms-and-conditions"}>
                        <a className="dropdown-item">Términos y condiciones</a>
                      </Link>
                    </li>
                    <li>
                      <Link href={"/legal-notice"}>
                        <a className="dropdown-item">Aviso legal y DMCA</a>
                      </Link>
                    </li>
                    <li>
                      <Link href={"/cookies-policy"}>
                        <a className="dropdown-item">Política de cookies</a>
                      </Link>
                    </li>
                    <li>
                      <Link href={"/"}>
                        <a className="dropdown-item">© Stardeos</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </aside>
            )}
            {children}
          </div>
        </div>

        <style jsx global>{`
          .sdbr {
            ::-webkit-scrollbar {
              width: 3px;
              height: 3px; /* Set the width of the scrollbar */              
            }
            
            ::-webkit-scrollbar-track {
              background-color: transparent; /* Set the background color of the scrollbar track */              
            }
            
            ::-webkit-scrollbar-thumb {
              background-color: #FFFFFF55; /* Set the color of the scrollbar thumb */
              border-radius: 5px; /* Set the border-radius of the scrollbar thumb */
              
            }
            
            ::-webkit-scrollbar-thumb:hover {
              background-color: #E7F3F388; /* Set the color of the scrollbar thumb on hover */
            }

            -webkit-transition: all 1s ease-in-out;
            transition: all 0.5s ease-in-out;
            margin-left: 0;
          }
          
          .sdbr.collapsed {
            margin-left: -220px;
          }

          .stbxcnt {
            -webkit-transition: all 1s ease-in-out;
            transition: all 0.5s ease-in-out;
          }
          
          .stbxcnt.darked {
            opacity: 0.3;
          }

          .at-wrapper {
            // background-image: url("/assets/images/bg.jpg");
            background-color: #020410;
            background-size: cover;
            background-attachment: fixed;
          }

          .stbxcnt {
            min-height: 100vh;
            width: inherit;
            flex: 1;
            min-width: 0;
          }

          .at-header {
            background-color: #0E1121;
            border-bottom: 1px solid #3A4065;
            height: auto;
            padding-bottom: 15px;
          }

          .at-videodetails {
            max-width: 1200px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 1fr 400px;
            gap: 30px;

            @media screen and (max-width: 1024px) {
              grid-template-columns: 1fr;
            }

            .at-content {
              padding: 0px;
              background: none !important;
              box-shadow: none;
              width: 100%;
            }

            .at-sidebar {
              width: 83%;
              background: none;
              box-shadow: none;
              padding: 0px;
              margin: 0px;
              justify-self: center;

              @media screen and (min-width: 1024px) {
                justify-self: start;
                margin-left: 5%;
              }

            }
          }

          .at-videocontent {
            h2 {
              font-size: 2.5rem;
              font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
                Helvetica, Arial, sans-serif, "Apple Color Emoji",
                "Segoe UI Emoji", "Segoe UI Symbol" !important;
              font-weight: 500;
              margin-bottom: 10px;
            }

            .at-viewsbox {
              color: #747d8c;
              font-weight: 400;
              font-size: 14px !important;
              font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
                Helvetica, Arial, sans-serif, "Apple Color Emoji",
                "Segoe UI Emoji", "Segoe UI Symbol";
            }
          }

          .at-addbox {
            background: linear-gradient(45deg, #bde9fe, #ffc4e8);
            color: white;
            border: none;
            height: 210px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
          }

          .at-userboxarea-grid {
            display: flex;
            align-items: center;
          }

          .at-userbox {
            .at-themeimage {
              width: 50px;
              height: 50px;
            }
          }
        `}</style>
      </div>
    );
  }, [children, description, image, styleClass, title, isCollapsed, isDarked]);
}
