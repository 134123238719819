import { EVideoFilters } from '@enums/videoFilter.enum';
import { ILikeOrDislikeInput } from '@interfaces/video.interface';
import { HttpService } from '@services/base';
import config from "@config/index";

class VideoService extends HttpService {
  private readonly prefix: string = 'v2/videos';
  private readonly uploadPrefix: string = process.env.NEXT_PUBLIC_API_ENDPOINT_UPLOAD + '/v2/video-edit';

  /**
   * upload video
   * @param data
   * @param config
   */
  upload = (data: any, config: any = {}): Promise<any> =>
    this.post(`${this.uploadPrefix}/create`, data, config);

  uploadTrial = (data: any, config: any = {}): Promise<any> =>
    this.post(`${this.uploadPrefix}/create-trial`, data, config);

  /**
   * edit video
   * @param data
   * @param id
   * @returns
   */
  edit = (data: any, id: string): Promise<any> =>
    this.post(`${this.prefix}/edit?id=${id}`, data);

  addPeer = (data: any, config: any = {}): Promise<any> =>
    this.put(`${this.prefix}/updatePeers`, data, config);


  findTrial = (options: {filter: EVideoFilters, categories: string[], userId: string}, page: number = 1): Promise<any> => {
      return this.get(`${this.prefix}/trial?filter=${options.filter}&page=${page}`);
  }

  findAll = (options: {filter: EVideoFilters, categories: string[], userId: string}, page: number = 1): Promise<any> => {

    let categories = '';
    for (let i = 0; i < options.categories.length; i++) {
      categories += '&categories[' + i + ']=' + options.categories[i];
    }
    return this.get(`${this.prefix}/?filter=${options.filter}&page=${page}&userId=${options.userId}${categories}`);
  };

  findAllWithStreams = (options: {filter: EVideoFilters, categories: string[], userId: string}, page: number = 1): Promise<any> => {


    let categories = '';
    for (let i = 0; i < options.categories.length; i++) {
      categories += '&categories[' + i + ']=' + options.categories[i];
    }
    return this.get(`${this.prefix}/streams/?filter=${options.filter}&page=${page}&userId=${options.userId}${categories}`);
  };

  discover = (filter: string, page: number = 1): Promise<any> =>
    this.get(`${this.prefix}/discover?categories=${filter}&page=${page}`);

  HomeDiscover = (): Promise<any> =>
    this.get(`${this.prefix}/?filter=LATEST`);

  browse = (search: string, page: number = 1): Promise<any> =>
    this.get(`${this.prefix}/browse?q=${search}&page=${page}`);

  getAd = (type: string): Promise<any> => this.get(process.env.NEXT_PUBLIC_ADS_STARDEOS_URL! +'a/' + type);

  /**
   * get user videoslist
   * @param options
   * @param page
   */
  getCurrentVideos = (options: {}, page: number = 1): Promise<any> =>
    this.get(`${this.prefix}/me?page=${page}`);

  /**
   * show the video detail
   * @param id
   */
  detail = (id: string): Promise<any> =>
    this.get(`${this.prefix}/${id}?v=${new Date().getTime()}`);

  recommended = (id: string, page: number): Promise<any> =>
    this.get(`${this.prefix}/recommended/${id}?page=${page}`);

  /**
   * Get edit Video detail
   * @param id
   * @param body
   * @returns
   */
  editVideoDetail = (id: string, body: any): Promise<any> =>
    this.put(`${this.uploadPrefix}/${id}`, body);

  /**
   * mark like and dislike a video
   * @param body
   * @param like: like, dislike or unlike
   */
  markLikeOrDislike = (body: ILikeOrDislikeInput, like: string) =>
    this.post(`${this.prefix}/${like}`, body);

  /**
   * Get video's likes/dislikes
   * @param id: video id
   */
  getVideoLikes = (id: string) =>
    this.get(`${this.prefix}/like/${id}`);

  /**
   * cancel video Upload
   */

  cancelToken = () => this.cancel();

  /**
   * get user Videos
   * @param userId
   * @param page
   */
  myVideos = (userId: string, page: number): Promise<any> =>
    this.get(`${this.prefix}?channel=${userId}&page=${page}`);
  /**
   * get most viewed video
   */
  mostViewed = (body: any): Promise<any> =>
    this.post(`${this.prefix}/mostViewed`, body);

  /**
   * get related Videos
   * @param query
   * @param page
   * @param body
   * @returns
   */
  relatedVideos = (query: string, page: number, body: any): Promise<any> =>
    this.post(`${this.prefix}/search?query=${query}&page=${page}`, body);

  /**
   * get search suggestions
   * @param query
   * @returns
   */
  suggestedTitles = (query: string): Promise<any> =>
    this.get(`${this.prefix}/suggestions?query=${query}`);

  suggestedMyVideoTitles = (query: string): Promise<any> =>
    this.get(`${this.prefix}/myVideoSuggestions?query=${query}`);
  /**
   * delete Video
   * @returns
   * @param id
   */
  deleteVideo = (id: string): Promise<any> =>
    this.delete(`${this.prefix}/delete/${id}`);


  featureVideo = (id: string): Promise<any> =>
    this.post(`${this.prefix}/feature/${id}`, {});
}

export const videoService = new VideoService();
