import { HttpService } from "@services/base";

class NotificationService extends HttpService {
  private readonly prefix: string = "v2/notifications";

  getNotifications = (): Promise<any> => this.get(`${this.prefix}`);

  markRead = (id: string): Promise<any> => this.delete(`${this.prefix}/${id}`);
}

export const notificationService = new NotificationService();
