import React from "react";
import Link from "next/link";
import { EVideoFilters } from "@enums/videoFilter.enum";
import useTranslation from "next-translate/useTranslation";
import { useAuth } from "@hooks/useAuth";

export const PaidSubscriptionComponent = ({ active, setActive,  userId, setUserId, setCategories, reload }: any) => {
  const { t } = useTranslation("common");
  const { user } = useAuth();

  return (
    <React.Fragment>
      <div className="item">
        <Link href="/">
          <a
            onClick={() => {
              setActive(EVideoFilters.PAIDSUBSCRIPTIONS);
              setUserId(userId);
              setCategories([]);
              reload()
            }}
            className={`${active && "active"}`}
          >
            <i className="icon-user-group1"></i>
            <span>
              {t("banner.paidsubscriptions")}
            </span>
          </a>
        </Link>
      </div>
    </React.Fragment>
  );
};
