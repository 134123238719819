import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { DOMAIN } from "../constants/domain";

export default function AuthLayout({
  children,
  title,
  descripcion,
  styleClass,
}: any) {
  const [, setLocale] = useState("es");
  const router = useRouter();

  useEffect(() => {
    if (router.locale) {
      setLocale(router.locale);
    }
  }, [router]);

  return (
    <div className={styleClass}>
      <Head>
        <title>{`${title}`}</title>
        <meta name="description" content={descripcion} />
        <link rel="canonical" href={`${DOMAIN}${router.pathname}`} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Stardeos" />
        <meta property="og:title" content={`${title}`} />
        <meta property="og:description" content={descripcion} />
        <meta name="twitter:title" content={title} />
        <meta
          property="og:image"
          content={"/assets/images/stardeos_bg_meta.jpeg"}
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta name="theme-color" content="#000000" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      </Head>
      <>{children}</>
    </div>
  );
}
