import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICommentInput } from "@interfaces/comment.interface";
import { commentService } from "@services/comment";
import { getStardusts } from "@store/auth/auth.async.func";

export const getVideoComments = createAsyncThunk(
  "comment/getVideoComments",
  async (body: any, thunkAPI) => {
    body.load(true);
    body.id = body.videoId; 
    try {
      const response = await commentService.getComment(
        body       
      );
      body.load(false);
      return { ...response.data.meta, comments: response.data.comments };
    } catch (error: any) {
      const err = error.response.data.message;

      body.load(false);
      return thunkAPI.rejectWithValue("Something went wrong " + err);
    }
  }
);

export const createVideoComments = createAsyncThunk(
  "comment/createVideoComment",
  async (body: ICommentInput, thunkAPI) => {
    try {
      const response = await commentService.createComment(body);
      thunkAPI.dispatch(getStardusts({}));
      return response.data;
    } catch (error: any) {
      const err = error.response.data.message;
      console.log(err);
      return thunkAPI.rejectWithValue("Something went wrong " + err);
    }
  }
);

export const createVideoCommentReplies = createAsyncThunk(
  "comment/createReply",
  async (body: any, thunkAPI) => {
    try {
      const response = await commentService.createReply(body);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const err = error.response.data.message;
      return thunkAPI.rejectWithValue("Something went wrong " + err);
    }
  }
);
export const markLikeOrDislikeComment = createAsyncThunk(
  "comment/markLikeOrDislike",
  async (body: any, thunkAPI) => {
    try {
      body.load(true);
      const response = await commentService.markLikeOrDislike(
        body.body,
        body.type
      );
      body.load(false);
      return {
        data: response.data,
        parent: body.parent,
        status: response.status,
      };
    } catch (error: any) {
      const err = error.response.data.message;
      console.log(err);
      body.load(false);
      return thunkAPI.rejectWithValue("Something went wrong " + err);
    }
  }
);
