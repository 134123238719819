import {
  ICommentInput,
  ILikeOrDislikeComment,
} from "@interfaces/comment.interface";
import { HttpService } from "@services/base";

class CommentService extends HttpService {
  private readonly prefix: string = "v2/comments";

  /**
   * get comments
   * @param id
   * @param filter
   * @param page
   * @returns
   */
  getComment = ({ id, filter }: any, page: number=1): Promise<any> => {
    const url = `${this.prefix}/${id}?filter=${filter}&page=${page}`;
    return this.get(url);
  };

  /**
   * create a comment
   * @param body
   * @returns
   */
  createComment = (body: ICommentInput): Promise<any> =>
    this.post(`${this.prefix}`, body);

  /**
   *Like or dislike a comment
   * @param body
   */
  markLikeOrDislike = (body: ILikeOrDislikeComment, type: string) =>
    this.post(`${this.prefix}/${type}`, body);
  /**
   * delete a comment
   * @param id
   * @param body
   */
  deleteComment = (id: any, body: any) => {
    this.post(`${this.prefix}/delete/${id}`, body);
  };

  createReply = (body: any): Promise<any> => {
    return this.post(`${this.prefix}/reply/${body.commentId}`, {
      content: body.content,
      type: 0,
      parent: body.parent,
    });
  };
  /**
   * Get all liked or disliked comments
   * @param id
   *
   */
  getLikedComments = (id: string) => {
    return this.get(`${this.prefix}/like/${id}`);
  };
}

export const commentService = new CommentService();
