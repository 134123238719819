import React, {useEffect, useState} from "react";
import { Button } from "react-bootstrap";
import { Adsense } from "@ctrl/react-adsense";
import { videoService } from "@services/video";
import zIndex from "@material-ui/core/styles/zIndex";

export default function StardeosAd({ adType = "video", position = "initial", display = "initial", width = "70%", height = "auto", displayText = true, gradientBackground = false }: any){

  const [ open, setOpen ] = useState(true)
  const [ ad, setAd ] = useState("")
  const [ adImg, setAdImg ] = useState("")

  const getAd = async () => {
    const res = await videoService.getAd(adType);
    if (res.data) {
      setAd(
        res.data[4]
      )
      setAdImg(
        'https://ads.stardeos.com/b/' + res.data[1]
      )
    }
  }

  useEffect(() => {
    getAd();
    console.log(adImg)
  })

  if(open){

    return (
      <div style={{width: '100%', height: '100%', background: gradientBackground ? "linear-gradinet(45deg, rgb(173, 129, 255), rgb(141, 211, 255))" : "inherit"}} className={gradientBackground ? "background-gradient" : ""}>
        <div className={'ad-container' + ' ' + (open == true ? "open" : null)} style = {{ width: width, height: height, display: display, position: position, }} >

          {ad && (
            <div className="xfr-tos" onClick={e => window.location.href = 'https://ads.stardeos.com/'}>
              <img style={{position: 'relative', zIndex: 2}} src="https://stardeos-thumbnails.s3.eu-west-1.amazonaws.com/big-adsminibanners.png" alt=""/>
            </div>
          )}
          {ad && (<img src={adImg} style={{width: '100%', height: '100%', position: 'relative'}}
                       onClick={() => window.open(ad ? ad : 'http://ads.stardeos.com/', '_blank')}/>)}
          <div className="ad-sponsors" onClick={() => window.open(ad ? ad : 'http://ads.stardeos.com/', '_blank')} >
            {
              !ad && (
                displayText ?
                (<div>
                  ¡Anuncia <b>LO QUE QUIERAS</b>!
                  <br/>A partir de 5€-10,000 visitas. ¡Precios locos de salida!
                  <br/><b>CLIC AQUÍ</b> - http://ads.stardeos.com/
                </div>)
                  :
                  (<></>)
              )
            }


          </div>

          <div style={{width: '100%', height: '100%'}}>

          </div>

          <Button style={{zIndex: 9}} className={'close-button'} onClick={() => setOpen(false)} >
            <i className="icon-delete" />
          </Button>

        </div>


        <style jsx global>{`
      .xfr-tos {
        position: absolute;
        top: 0;
        right: 0;
      }
        .ad-container {
          display: none;
          background: linear-gradient(45deg, #FF5093, #9A37E7);
          border-radius: 3px;
          align-items: center;
          position: absolute;
          top: 80%;
          left: 15%;
          
          i {
            font-size: 10px;
          }

        }

        .ad-sponsors {
          margin: 0px 10px 0px 10px;
          text-align: center;
          color: #000;
          font-family: var(--primary-font);
          font-size: 8px;

          @media screen and (min-width: 1200px){
            font-size: 18px;
          }
        }
        
        .open {
          display: grid;
        }

        .close-button {

          width: 18px;
          height: 18px;
          background-color: #000;
          justify-self: end;
          align-self: start;
          position: absolute;
          border-top-right-radius: 2px;
          color: #fff;
          display: grid;
          align-items: center;
          justify-content: center;

        }

      `}</style>

      </div>
    )

  }else{

    return( <></> )

  }

}