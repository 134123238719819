import { NextSeo } from 'next-seo';
import useTranslation from "next-translate/useTranslation";
import { DOMAIN } from "../../constants/domain";

export default function SEO({ props }) {
    const { t } = useTranslation("common");
    const { router, pageProps } = props
    const { route } = router
    const { video } = pageProps

    if (route == '/' ) {
        return <NextSeo
            title={t("home.seo.title")}
            description={t("home.seo.description")}
            canonical={`${DOMAIN}/`}
            openGraph={{
                type: 'website',
                url: `${DOMAIN}/`,
                title: t("home.seo.title"),
                description: t("home.seo.description"),
                image: "/assets/images/stardeos_bg_meta.jpeg",
                site_name: 'Stardeos',
            }}
        />
    }

    if (route == '/video/[id]' && video) {

        return <NextSeo
            title={`${video.title}`}
            description={video.description}
            canonical={`${DOMAIN}/video/${video.id}`}
            openGraph={{
                type: 'website',
                url: `${DOMAIN}/video/${video.id}`,
                title: video.title,
                description: video.description,
                image: video.thumbnail,
                site_name: 'Stardeos',
            }}
        // facebook={{
        //     appId: 1156633068037164,
        // }}
        // twitter={{
        //     handle: '@handle',
        //     site: '@site',
        //     cardType: 'summary_large_image',
        // }}
        />
    }
    return null
}