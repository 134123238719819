import Link from "next/link";
import React from "react";
import useTranslation from "next-translate/useTranslation";

export const CreatorComponent = () => {
  const { t } = useTranslation("common");
  return (
    <React.Fragment>
      <Link href="/creator">
        <a className="button primary" title="be a creater" style={{maxWidth: "450px"}}>
          <i className="fa fa-video"></i>{" "}
          <span>{t("navigation.beACreator")}</span>
        </a>
      </Link>
    </React.Fragment>
  );
};
