import { createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "@services/auth";
import { notify } from "@utils/toast.utils";
import useTranslation from "next-translate/useTranslation";

export const updatePassword = createAsyncThunk(
  "user/updatePassword",
  async (body: any, thunkAPI) => {
    const { t } = useTranslation("common");
    try {
      const response = await authService.resetPassword(body);
      notify("success", t(response.data.message), {theme: 'colored'});
      return response.data;
    } catch (error: any) {
      const err = error.response.data.message;
      console.log(err);
      return thunkAPI.rejectWithValue("Something went wrong " + err);
    }
  }
);

export const getStardusts = createAsyncThunk(
  "user/getStardust",
  async (body: any, thunkAPI) => {
    try {
      const res = await authService.getUserStardust();
      return res.data.data.stardust;
    } catch (error: any) {
      const err = error.response.data.message;
      console.log(err);
      return thunkAPI.rejectWithValue("Something went wrong " + err);
    }
  }
);

export const updateEmail = createAsyncThunk(
  "user/updateEmail",
  async (body: any, thunkAPI) => {
    const { t } = useTranslation("common");
    try {
      const response = await authService.updateEmail(body);
      notify("success", t(response.data.message), {theme: 'colored'});
      return response.data;
    } catch (error: any) {
      const err = error.response.data.message;
      notify("error", t("messages.somethingWentWrong"), {theme: 'colored'});
      return thunkAPI.rejectWithValue("Something went wrong " + err);
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "user/getUser",
  async (body: any, thunkAPI) => {
    try {
      const response = await authService.getCurrentUser();
      return response.data.user;
    } catch (error: any) {
      const err = error.response.data.message;
      return thunkAPI.rejectWithValue("Something went wrong " + err);
    }
  }
);
