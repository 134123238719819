import moment from "moment";

export const calculateTimeStampHandler = (date: any) => {
  const duration = moment.duration(moment(new Date()).diff(moment(date)));
  const days = Math.round(duration.asDays());
  const hours = duration.asHours();
  const mins = duration.asMinutes();
  const seconds = duration.asSeconds();
  if (days > 0) return days + " day ago";
  else if (hours >= 1) return Math.round(hours) + " hour ago";
  else if (mins >= 1) return Math.round(mins) + " min ago";
  else if (seconds < 0) return 0 + " sec ago";
  else return Math.round(seconds) + " sec ago";
};
