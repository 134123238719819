import VideoLayout from '@layouts/video.layout'
import useTranslation from 'next-translate/useTranslation'
// import Link from 'next/link'
import React from 'react'


export const LoginRequired = () => {
    const { t } = useTranslation("common")
    return (
        <VideoLayout title={"Stardeos"} description={"Stardeos"} path={''}>
            <h1 className="title">{t("loginRequired.title")}</h1>
        </VideoLayout>

    )
}

