import React, { useEffect, useState } from "react";
import { ProfilePictureComponent } from "@components/shared-components/profile-picture/profile-picture.component";
import Dropdown from "react-bootstrap/Dropdown";
import { ERoles } from "@enums/roles.enum";
import Link from "next/link";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { useAuth } from "@hooks/useAuth";

const dummy: any = {
  avatar: "",
  fullName: "",
  email: "",
};

interface HeaderBarProps {
  handleDropdownClick: (isProfileOpen: boolean) => void;
}

export const ProfileDropdown: React.FC<HeaderBarProps> = ({ handleDropdownClick }) => {
  const { t } = useTranslation("common");
  const { user, logout } = useAuth();
  const [langPicker, setLangPicker] = useState(false);
  const router = useRouter();
  const [path, setPath] = useState("/");
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  useEffect(() => {
    setPath(router.asPath);
  }, [router]);

  const countryOptions = [
    { key: "en", value: "en", flag: "us", text: "English" },
    { key: "es", value: "es", flag: "es", text: "Español" },
  ];

  const handleDropdownToggle = () => {
    setIsProfileOpen(!isProfileOpen);
    handleDropdownClick(isProfileOpen);
  };

  return (
    <React.Fragment>
      <Dropdown className="at-dropdown at-profiledropdown" onToggle={handleDropdownToggle}>
        <Dropdown.Toggle id="dropdown-basic">
          <ProfilePictureComponent
            img={user && user.avatar}
            initials={user ? user.username : dummy.fullName}
            size={40}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className="at-dropdowncontent">
          <div className="at-heading">
            <div className="at-title">
              <ProfilePictureComponent
                img={user && user.avatar}
                initials={user ? user.username : dummy.fullName}
                size={35}
              />
              <h3>{user ? user.username : dummy.fullName}</h3>
              {/* <h4>{user ? user.email : dummy.email}</h4> */}
            </div>
            <Link href="/profile">
              <a className="at-profile-view">
                {t("navigation.profileDropdown.viewProfile")}&nbsp;&nbsp;
                <i style={{lineHeight: "0px"}}>
                  <img src="/assets/images/profile-icons/rightarrow.svg" alt="arrow" width="16" height="16"/>
                </i>
              </a>
            </Link>
          </div>
          <div className="at-dropdownlist">
            {/* <Link href={`/profile`}>
              <a className="dropdown-item">
                <div className="icon-container">
                  <i className="far fa-user"></i>
                </div>
                <span>{t("navigation.profileDropdown.profile")}</span>
              </a>
            </Link> */}
            <div className="at-dropdownlist-content">
              {user && (user.roles.includes(ERoles.CREATOR) || user.roles.includes(ERoles.TRIAL)) && (
                <Link href={`/${user.username}`}>
                  <a className="dropdown-item">
                    <div className="icon-container">
                      <i className="far fa-user"></i>
                    </div>
                    <span>{t("navigation.profileDropdown.yourChannel")}</span>
                  </a>
                </Link>
              )}
              {user && (user.roles.includes(ERoles.CREATOR) || user.roles.includes(ERoles.TRIAL)) && (
                <React.Fragment>
                  <Link href={`/my-videos`}>
                    <a className="dropdown-item">
                      <div className="icon-container">
                        <i className="icon-videos"/>
                      </div>
                      <span>{t("navigation.profileDropdown.videos")}</span>
                    </a>
                  </Link>
                </React.Fragment>
              )}
              {user && (user.roles.includes(ERoles.CREATOR) || user.roles.includes(ERoles.TRIAL)) && (
                <React.Fragment>
                  <Link href={`/analytics`}>
                    <a className="dropdown-item">
                      <div className="icon-container">
                        <i className="icon-videos"/>
                      </div>
                      <span>{"Analíticas"}</span>
                    </a>
                  </Link>
                </React.Fragment>
              )}
              {user && (user.roles.includes(ERoles.CREATOR)) && (
                <React.Fragment>
                  <Link href={`/upload-video`}>
                    <a className="dropdown-item">
                      <div className="icon-container">
                        <i className="icon-videos"/>
                      </div>
                      <span>{"Subir video"}</span>
                    </a>
                  </Link>
                </React.Fragment>
              )}
              {user && (user.roles.includes(ERoles.TRIAL)) && (
                <React.Fragment>
                  <Link href={`/upload-trial-video`}>
                    <a className="dropdown-item">
                      <div className="icon-container">
                        <i className="icon-videos"/>
                      </div>
                      <span>{"Subir video"}</span>
                    </a>
                  </Link>
                </React.Fragment>
              )}
              {user && (!user.roles.includes(ERoles.TRIAL) && !user.roles.includes(ERoles.CREATOR)) && (
                <React.Fragment>
                  <Link href={`/creator`}>
                    <a className="dropdown-item">
                      <div className="icon-container">
                        <i className="icon-videos"/>
                      </div>
                      <span>{"Conviértete en creador"}</span>
                    </a>
                  </Link>
                </React.Fragment>
              )}
              <Link href="/buy/buy-stardust">
                <a className="dropdown-item">
                  <div className="icon-container">
                    <i className="icon-ratingstars"></i>
                  </div>
                  <span>{t("navigation.profileDropdown.stardust")}</span>
                </a>
              </Link>
              <Dropdown.Item onClick={() => setLangPicker(true)}>
                <div className="icon-container">
                  <i className="fas fa-language"></i>
                </div>
                <span>{t("navigation.profileDropdown.languages")}</span>
                <i className="fa fa-angle-right"></i>
              </Dropdown.Item>
            </div>
            <div className="at-dropdownlist-content">
              <React.Fragment>
                <Link href={`/profile?tab=4`}>
                  <a className="dropdown-item">
                    <div className="icon-container">
                      <i>
                        <img src="/assets/images/profile-icons/currency-dollar.svg" alt="star" width="22" height="22"/>
                      </i>
                    </div>
                    <span>{t("navigation.profileDropdown.membership")}</span>
                  </a>
                </Link>
              </React.Fragment>
              <React.Fragment>
                <Link href={`/profile?tab=6`}>
                  <a className="dropdown-item">
                    <div className="icon-container">
                      <i>
                        <img src="/assets/images/profile-icons/database.svg" alt="star" width="22" height="22"/>
                      </i>
                    </div>
                    <span>{t("navigation.profileDropdown.stardeosData")}</span>
                  </a>
                </Link>
              </React.Fragment>
            </div>
            <Dropdown.Item onClick={() => logout()}>
              <div className="icon-container-logout">
                <i className="fas fa-sign-out-alt" style={{ color: "#D44F4F" }}></i>
              </div>
              <span style={{ color: "#D44F4F" }}>{t("navigation.profileDropdown.logout")}</span>
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {langPicker && (
        <div className="language-picker">
          {countryOptions.map((c, i) => {
            return (
              <Link href={path} locale={c.value} key={`${i}-lang`}>
                <a onClick={() => setLangPicker(false)}>
                  <div className="item">{c.text}</div>
                </a>
              </Link>
            );
          })}
        </div>
      )}

      <style jsx global>{`
        .at-dropdownlist-content {
          border-bottom: 1.5px solid #3A4065;
        }

        .at-profile-view {
          font-size: 12px;
          font-weight: 500;
          padding: 12px 16px;
          border: 1.5px solid #3A4065 !important;
          border-radius: 20px;
          display: flex;
        }

        .at-profiledropdown {
          .at-dropdowncontent {
            border-radius: 8px;
            background: #191D32;
            box-shadow: 0 0 20px 3px rgb(0 0 0 / 10%) !important;
            // top: 50px !important;

            .dropdown-item {
              font-family: var(--primary-font);
              font-weight: 450;
              padding: 10px 24px;

              .icon-container {
                width: 35px;
              }

              span {
                font-weight: 450;
                font-size: 15px;
              }

              i {
                color: #E7F3F3;
                font-size: 18px;
              }
            }

            .at-heading {
              display: flex;
              justify-content: space-between;
              padding: 10px 20px 15px 25px;

              .at-title {
                display: flex;
                align-items: center;
                gap: 10px;

                h3 {
                  margin-bottom: 0px;
                  margin-top: 4px;
                  font-family: var(--primary-font);
                  font-weight: 500;
                  font-size: 16px;
                }

                h4 {
                  margin-bottom: 0px;
                  font-family: var(--primary-font);
                  font-weight: 300;
                  color: #a4b0be;
                  font-size: 13px;
                }
              }

              i {
                font-size: 20px;
              }
            }
          }
        }

        .language-picker {
          position: absolute;
          right: 15px;
          top: 61px;
          width: 200px;
          background-color: #191D32;
          color: white;
          padding: 20px 10px 10px;
          border-radius: 8px;
          --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -1px rgba(0, 0, 0, 0.06);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
            var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

          .item {
            font-size: 14px;
            margin-bottom: 10px;
            font-family: var(--primary-font);
            color: #E7F3F3;
            padding-left: 10px;
            font-weight: 300;
          }
        }
      `}</style>
    </React.Fragment>
  );
};
