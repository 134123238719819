import { createSlice } from "@reduxjs/toolkit";
import { EVideoFilters } from "@enums/videoFilter.enum";
import { getStardusts, getUserInfo, updateEmail } from "./auth.async.func";

const initialState = {
  user: null,
  access_token: null,
  message: "",
  subscribers: null,
  submit: false,
  filePercentage: 0,
  card: null,
  filter: EVideoFilters.TRENDING,
  modal: false,
  stardust: 0,
};

const slice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user;
      state.access_token = action.payload.access_token;
      state.message = "";
      state.stardust = action.payload.user.stardust;
    },
    signUp: (state, action) => {
      state.user = action.payload.user;
      state.access_token = action.payload.access_token;
      state.message = "";
    },

    error: (state, action) => {
      state.message = action.payload;
    },

    clear: (state) => {
      state.message = "";
    },
    getUser: (state, action) => {
      state.user = action.payload;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    subscribers: (state, action) => {
      state.subscribers = action.payload;
    },
    setFilter: (state, action) => {
      if (state.filter != action.payload) state.filter = action.payload;
    },
    getCard: (state, action) => {
      state.card = action.payload;
    },
    setModal: (state: any = {}) => {
      state.modal = !state.modal;
    },
    updateStardust: (state, action) => {
      state.stardust = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.access_token = null;
      state.filter = EVideoFilters.HOME;
    },
  },
  extraReducers: {
    [updateEmail.fulfilled.toString()]: (state, { payload }) => {
      state.user = payload.user;
    },
    [getStardusts.fulfilled.toString()]: (state, { payload }) => {
      state.stardust = payload;
    },
    [getUserInfo.fulfilled.toString()]: (state, { payload }) => {
      state.user = payload;
    },
  },
});

export const { reducer: authReducer, actions: authActions } = slice;
