import React from "react";
import { getProfilePicture } from "@utils/video";
interface IProps {
  img?: any;
  initials?: string;
  absolute?: boolean;
  size?: number;
}

export const ProfilePictureComponent: React.FC<IProps> = ({
  img,
  initials,
  absolute = true,
  size,
}) => {
  let acronym;
  let image;
  if (initials) {
    const matches: any = initials.match(/\b(\w)/g);
    if (matches.length > 2) matches.splice(2);
    acronym = matches.join("");
  }

  image = absolute ? img : getProfilePicture(img);

  return (
    <React.Fragment>
      <figure className="at-themeimage" style={{width: `${size}px`, height: `${size}px`}}>
        {image ? <img src={image} alt="profile" /> : initials ? acronym : ""}
      </figure>
      <style jsx>{`
        .at-themeimage {
          background: #b5e0fd;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          color: white;
          font-weight: 600;
          font-family: var(--primary-font);
        }
      `}</style>
    </React.Fragment>
  );
};
