import { useState, useEffect } from "react";
import { notificationService } from "@services/notification";

export const useNotifications = () => {
  const [notifications, setNotifications] = useState<any>([]);
  const [count, setCount] = useState<number>(0);
  const getAllNotifications = async () => {
    try {
      const { data } = await notificationService.getNotifications();
      setNotifications(data.notifications);
      setCount(data?.meta.total || 0);
    } catch (err:any) {
      console.warn("Notifications ::", err?.message)
    }
  };

  const markRead = async (id: string) => {
    await notificationService.markRead(id);
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  return [notifications, count, { markRead }];
};
