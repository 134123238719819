import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useNotifications } from "@hooks/useNotifications";

import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import { calculateTimeStampHandler } from "@utils/calculateTimeStampHandler.utils";
import { ProfilePictureComponent } from "@components/shared-components/profile-picture/profile-picture.component";
import { DOMAIN } from "../../../constants/domain";

interface HeaderBarProps {
  handleDropdownClick: (isNotificationOpen: boolean) => void;
}

export const NotificationComponent: React.FC<HeaderBarProps> = ({ handleDropdownClick })=> {
  const { t } = useTranslation("common");
  const [notifications, count, { markRead }] = useNotifications();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsNotificationOpen(!isNotificationOpen);
    handleDropdownClick(isNotificationOpen);
  };

  return (
    <React.Fragment>
      <Dropdown className="at-dropdown at-notificationdropdown" show={isNotificationOpen} onToggle={handleDropdownToggle}>
        <Dropdown.Toggle id="dropdown-basic">
          <i className="icon-notification-bell"></i>
          {count != 0 && <span className="at-count">{count}</span>}
        </Dropdown.Toggle>
        <Dropdown.Menu className="at-dropdowncontent">
          <div className="at-heading">
            <h3>{t("navigation.notifications")}</h3>
            {/* <Link href="/settings?tab=2">
              <a className="at-settingbtn">
                <i className="icon-setting"></i>
              </a>
            </Link> */}
          </div>
          <div className="at-dropdownlist">
            {notifications.map((notification: any, index: any) => {
              return (
                <div key={index} onClick={() => markRead(notification.id)}>
                  <Link href={(notification.url.startsWith(DOMAIN) ? notification.url.substr(DOMAIN.length) : notification.url) ?? '/'}>
                    <a className="dropdown-item">
                      <div className="at-infobox">
                        <div className="at-imagebox">
                          <ProfilePictureComponent
                            img={notification.img}
                            absolute={true}
                          />
                        </div>
                        <div className="at-infocontent">
                          { notification.type === 'VIDEO' && (
                              <h4>{notification.channelName}<span> ha subido un nuevo vídeo</span></h4>
                          )}
                          { notification.type === 'REPLY' && (
                              <h4>{notification.channelName}<span> ha respondido a tu comentario</span></h4>
                          )}
                          { notification.type === 'STARDUST' && (
                              <h4><span>Has recibido </span>{notification.amount}<span> stardust en tu vídeo</span></h4>
                          )}
                          <h5>{notification.title}</h5>
                          <span className="at-time">
                            {calculateTimeStampHandler(notification.createdAt)}
                          </span>
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>
              );
            })}
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <style jsx>{`

        .icon-notification-bell {
          cursor: pointer;
        }

        .at-dropdownlist {
          padding: 0px;
        }

        .dropdown-menu {
          border-radius: 8px;
        }

        .at-heading {
          h3 {
            font-family: var(--primary-font);
            font-weight: 500;
            margin: 0px;
          }
        }

        .dropdown-item {
          .at-infobox {
            padding: 10px 20px;
            border-top: 1px solid #3A4065;
            gap: 10px;

            .at-imagebox {
              margin: 0px;
            }

            .at-infocontent {
              width: 100%;

              h4 {
                font-family: var(--primary-font);
                text-transform: unset;
                font-size: 12px;
                margin-bottom: 3px;
                font-weight: 700;
                color: white;

                span {
                  font-weight: 450;
                  color: white;
                }
              }

              h5 {
                color: white;
              }

              .at-time {
                font-family: var(--primary-font);
                font-size: 11px;
                font-weight: 400;
                margin-bottom: 0px;
              }

              h5 {
                font-family: var(--primary-font);
                font-weight: 600;
                font-size: 12px;
                color: hsl(256deg 86% 81%);
              }
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

