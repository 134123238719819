import { HttpService } from '@services/base';
import {
  IAuthLogin,
  IAuthResetPassword,
  IAuthSignUp,
  IAuthVerifyPin
} from '@interfaces/auth.interface';

class AuthService extends HttpService {
  private readonly prefix: string = 'v2/auth';

  login = (data: IAuthLogin): Promise<any> =>
    this.post(`${this.prefix}/login`, data);

  logout = (data: any): Promise<any> =>
    this.post(`${this.prefix}/logout`, data);

  signUp = (data: IAuthSignUp): Promise<any> =>
    this.post(`${this.prefix}/signUp`, data);


  forgetPassword = (data: any): Promise<any> =>
    this.post(`${this.prefix}/forget-password`, data);

  resetPassword = (data: IAuthResetPassword): Promise<any> =>
    this.post(`${this.prefix}/reset-password`, data);

  verifyPin = (data: IAuthVerifyPin): Promise<any> =>
    this.post(`${this.prefix}/verify-pin`, data);

  verifyEmail = (data: any): Promise<any> =>
    this.post(`${this.prefix}/verify-register`, data);

  recoverPassword = (data: any): Promise<any> =>
    this.post(`${this.prefix}/recover-password`, data);

  recoverUser = (data: any): Promise<any> =>
    this.post(`${this.prefix}/recover-user`, data);

  updateEmail = (data: any): Promise<any> =>
    this.put(`${this.prefix}/update/user`, data);

  getUserStardust = (): Promise<any> => this.get(`stardust/getStardust`);

  getCurrentUser = (v: number = 0): Promise<any> =>
    this.get(`${this.prefix}/me?v=${v}`);

  resendEmailVerification = (data: any): Promise<any> =>
    this.post(`${this.prefix}/resend-email`, data);

  ban = (data: any): Promise<any> =>
    this.post(`${this.prefix}/ban`, data);

  declineCreator = (data: any): Promise<any> =>
    this.post(`${this.prefix}/declineCreator`, data);

  deleteVideo = (data: any): Promise<any> =>
    this.post(`${this.prefix}/deleteVideo`, data);

  acceptCreator = (data: any): Promise<any> =>
    this.post(`${this.prefix}/acceptCreator`, data);

  unsubscribe = (email: string, code: string): Promise<any> =>
    this.get(`${this.prefix}/unsubscribe/${email}/${code}`);

  deleteUser = (password: string): Promise<{ data: boolean }> =>
    this.post(`${this.prefix}/delete-user`, { password });
}

export const authService = new AuthService();
