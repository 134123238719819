
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import "../styles/globals.css";
import "../styles/globals.scss";
import type { AppProps } from "next/app";
import "@y0c/react-datepicker/assets/styles/calendar.scss";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/effect-coverflow";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { store } from "@store/index";
import { Provider } from "react-redux";
import { AuthProvider } from "../context/auth.context";
import ADBlockDetector from "@components/adblock-detector/adblock-detector.component";
import { useDetectAdblock } from "@hooks/useDetectAdblock";
import { Maintenance } from "@components/maintenance/maintenance.component";
import Seo from '../components/seo'
// @ts-ignore
import { useRouter } from "next/router";
import * as ga from "@lib/ga";
/* import AppModal from "@components/modal-app/app.modal"; */

// WARNING: true state will turn the web USELESS
const MAINTENANCE = false;

const __Page_Next_Translate__ = function MyApp(props: AppProps) {
  const { Component, pageProps } = props;
  const ready = typeof window !== "undefined";
  /* const adBlockDetected = useDetectAdblock();*/ // Disabled (adsense policy)
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = ({ url }: any) => {
      ga.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  if (!ready) return null;

  // @ts-ignore
  const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY);

  return (
    <>
      <Seo {...{ props }} />
      <Provider store={store}>
        <Elements stripe={stripePromise}>
          <AuthProvider pageProps={pageProps}>
            {MAINTENANCE && <Maintenance />}
            <Component {...pageProps} />
            {/* {!MAINTENANCE && (
              <>
                { adBlockDetected 
                  ? <ADBlockDetector />
                  : <Component {...pageProps} /> 
                }
              </>
            )} */}
          </AuthProvider>
        </Elements>
      </Provider>
    </>
  );
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  