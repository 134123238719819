import { createSlice } from "@reduxjs/toolkit";
import { EFilter } from "../../enums";
import { IComment } from "../../interfaces/comment.interface";

import {
  getVideoComments,
  createVideoComments,
  markLikeOrDislikeComment,
} from "./async.func";

export interface ITypes {
  comments: IComment[] | any;
  message: string;
  submit: boolean;
  loading: boolean;
  currentPage: number;
  pages: number;
  total: number;
  filter: string;
}

const initialState: ITypes = {
  comments: [],
  message: "",
  submit: false,
  loading: true,
  currentPage: 1,
  pages: 1,
  total: 0,
  filter: EFilter.RATING,
};

const slice = createSlice({
  name: "comment",
  initialState: initialState,
  reducers: {
    commentAdded: (state, action) => {
      state.comments = [action.payload.comment, ...state.comments];
      state.total = state.total + 1;
    },
    commentReplyAdded: (state, action) => {
      state.comments.forEach((x: any) => {
        if (x._id == action.payload.id) {
          x.replies = [action.payload.comment, ...x.replies];
        }
      });
    },
    clear: (state) => {
      state.comments = [];
      state.currentPage = 1;
      state.pages = 1;
      state.total = 0;
    },
  },
  extraReducers: {
    [getVideoComments.fulfilled.toString()]: (state, action) => {

      if (
        // state.filter == action.payload.filter &&
        state.currentPage != parseInt(action.payload.page)
      ) {
        state.comments = state.comments.concat(action.payload.comments);
      } else {
        state.comments = action.payload.comments;
      }
      state.currentPage = action.payload.page;
      state.pages = action.payload.pages;
      state.total = action.payload.total;
      state.filter = action.payload.filter;
      state.loading = false;
    },
    [getVideoComments.pending.toString()]: (state) => {
      state.loading = true;
      state.message = "";
    },
    [getVideoComments.rejected.toString()]: (state, action) => {
      state.message = action.payload;
      state.comments = [];
      state.loading = false;
    },
    [createVideoComments.fulfilled.toString()]: (state) => {
      // state.comments = [action.payload.comment, ...state.comments];
      state.submit = false;
    },
    [createVideoComments.pending.toString()]: (state) => {
      state.submit = true;
      state.message = "";
    },
    [createVideoComments.rejected.toString()]: (state, action) => {
      state.message = action.payload;
      state.submit = false;
    },
    [markLikeOrDislikeComment.fulfilled.toString()]: (state, action) => {
      if (action.payload.data.video && action.payload.data.video.isReply) {
        const index = state.comments?.findIndex(
          (comment: any) => comment._id === action.payload.parent
        );
        const replyIndex = state.comments[index].replies?.findIndex(
          (comment: any) => comment._id === action.payload.data.video._id
        );

        state.comments[index].replies[replyIndex].likes = [
          ...action.payload.data.video.likes,
        ];
        state.comments[index].replies[replyIndex].dislikes = [
          ...action.payload.data.video.dislikes,
        ];
      } else if (action.payload.data.video) {
        const index = state.comments?.findIndex(
          (comment: any) => comment._id === action.payload.data.video._id
        );
        state.comments[index].likes = [...action.payload.data.video.likes];
        state.comments[index].dislikes = [
          ...action.payload.data.video.dislikes,
        ];
      }
    },
    [markLikeOrDislikeComment.pending.toString()]: (state) => {
      state.message = "";
    },
    [markLikeOrDislikeComment.rejected.toString()]: (state, action) => {
      state.message = action.payload;
    },
  },
});

export const { reducer: commentReducer, actions: commentActions } = slice;
