import React, { useEffect, useMemo, useState } from "react";
import Link from "next/link";
import {ERoles} from "@enums/roles.enum";
import Image from "next/image";
import stardeosLogo from "../../../public/assets/images/stardeos01.png";
import {ProfileDropdown} from "./profile-dropdown/profile-dropdown.component";
import {SearchBarComponent} from "./searchbar/searchbar.component";
import {NotificationComponent} from "./notification/notification.component";
import {RatingComponent} from "./rating/rating.component";
import {CreatorComponent} from "./creator/creator.component";
import useTranslation from "next-translate/useTranslation";
import {useAuth} from "@hooks/useAuth";

interface HeaderBarProps {
  toggleCollapse: () => void;
  toggleDropDown: (newValue: boolean) => void;
}

export const NavigationBar: React.FC<HeaderBarProps> = ({ toggleCollapse, toggleDropDown }) => {
  const {t} = useTranslation("common");
  const { user, refresh } = useAuth();
  const [toggleClass, setToggleClass] = useState<boolean>(false);

  const checkUploadPermission = async () => {
    if (user && !user?.roles?.includes('CREATOR')) {
      await refresh();
      await new Promise<void>((resolve): void => {
        setTimeout(() => resolve(), 10000);
      });
      await checkUploadPermission();
    }
  }

  useEffect( () => {
    checkUploadPermission();
  }, []);

  return useMemo(
    () => (
      <div
        className={
          user
            ? toggleClass
            ? "at-headerarea at-opensearch"
            : "at-headerarea"
            : toggleClass
            ? "at-headerarea at-opensearch headerwithlogout"
            : "at-headerarea headerwithlogout"
        }
      >
        <div style={{ marginRight: "20px" }} onClick={ toggleCollapse }>
          <img src = "/assets/images/input-icons/menu.svg"></img>
        </div>
        <strong className="at-logo">
          <Link href="/">
            <a
              onClick={() => {
                // dispatch(authActions.setFilter(EVideoFilters.TRENDING))
              }}
            >
              <div className="image-nav">
                <Image
                  src={stardeosLogo}
                  alt="Stardeos"
                  quality={100}
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </a>
          </Link>
        </strong>
        <div className="at-navarea">
          <div className="at-leftarea">
            <SearchBarComponent
              toggleClassHandler={() => setToggleClass(!toggleClass)}
              yourVideos={false}
            />
            {user && <RatingComponent/>}
          </div>
          <div className="at-rightarea">
            {/* <UploadComponent /> */}
            {user && ~user?.roles?.indexOf(ERoles.ADMIN) ?
              (
                <Link href="/trial">
                  <a className="button primary" title="trial" style={{maxWidth: "450px"}}>
                    <span>{"Videos de prueba"}</span>
                  </a>
                </Link>)
              : ""}
            {user ? (
              ~user?.roles?.indexOf(ERoles.CREATOR) ? (
                <Link href="/upload-video">
                  <a className="button primary" title="upload video" style={{maxWidth: "450px"}}>
                    <i className="icon-upload"></i>
                    <span>{t("navigation.upload")}</span>
                  </a>
                </Link>
              ) : (
                ~user?.roles?.indexOf(ERoles.TRIAL) ?
                  (<Link href="/upload-trial-video">
                    <a className="button primary" style={{maxWidth: "450px"}} title="upload video">
                      <i className="icon-upload"></i>
                      <span>{t("navigation.upload")}</span>
                    </a>
                  </Link>) :
                  <CreatorComponent/>
              )
            ) : (
              <Link href="/login">
                <a className="button primary" title="login">
                  <span>{t("navigation.login")}</span>
                </a>
              </Link>
            )}

            {user && (
              <React.Fragment>
                <NotificationComponent handleDropdownClick ={ toggleDropDown }/>
                <ProfileDropdown handleDropdownClick ={ toggleDropDown }/>
              </React.Fragment>
            )}
          </div>
          <div className="mobile-menu">
            {user ? (
              <React.Fragment>
                {/* <RatingComponent/> */}
                <ProfileDropdown handleDropdownClick ={ toggleDropDown }/>
              </React.Fragment>
            ) : (
              <div className="login">
                <Link href="/login">
                  <a className="login-a button primary">
                    <i className="far fa-user"></i>
                  </a>
                </Link>
              </div>
            )}
          </div>
        </div>

        <style jsx global>{`
          .login-a {
            width: 40px;
            display: flex;
            height: 40px;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            margin-left: 10px;
          }

          .image-nav {
            width: auto;
            height: 40px;
            position: relative;
          }

          .at-leftarea {
            display: flex;
          }

          .at-rightarea {
            gap: 10px;
            margin-left: 10px;

            @media screen and (max-width: 1110px) {
              display: none;
            }
          }

          .at-headerarea {
            padding: 15px 20px 0 25px;

            @media screen and (max-width: 580px) {
              flex-direction: row;
            }
          }

          .at-notificationdropdown {
            position: relative;
          }

          .at-profiledropdown {
            position: relative;
            margin: 0px;

            .at-dropdowncontent {
              position: absolute;
              margin: 0px;
              top: 46px !important;
              left: inherit !important;
              right: -13px !important;
              bottom: auto;
              transform: initial !important;
            }
          }
          .at-rightarea {
            .button {
              &.primary {
                display: flex;
                align-items: center;
                padding: 0px 20px;
                font-size: 12px;
                height: 40px;
                border-radius: 8px !important;

                @media screen and (max-width: 640px) {
                  max-width: 55px !important;
                }

                i {
                  margin-right: 10px !important;

                  @media screen and (max-width: 1110px) {
                    margin-right: 0px !important;
                  }
                }

                span {
                  font-family: var(--primary-font);

                  @media screen and (max-width: 1110px) {
                    display: none;
                  }
                }
              }
            }
          }

          @media screen and (max-width: 1200px) {
            .sdbr {
              display: none;
            }
          }

          @media screen and (max-width: 1040px) {
            .at-navarea {
            }

            .at-searchbox {
              display: none;
            }
          }

          .mobile-menu {
            display: none !important;

            @media screen and (max-width: 1110px) {
              display: contents !important;
              margin-left: 15px;
            }
          }
        `}</style>
      </div>
    ),
    [toggleClass, t, user]
  );
};
