import React from "react";
import Link from "next/link";
import Image from "next/image";
import { useAuth } from "@hooks/useAuth";
import { getIcon } from "@utils/getIcon.utils";
import _get from "lodash/get";
import { numberWithCommas } from "@hooks/functions";

export const RatingComponent = () => {
  const { user } = useAuth();
  const stardusts = _get(user, "stardusts", 0);

  return (
    <div className="at-ratingbox">
      <Link href="/buy/buy-stardust">
        <a style={{ display: "flex"}}>
          <div className="image-box">
            <Image
              src="/assets/images/star-header.svg"
              alt="icon"
              layout="fill"
              objectFit="contain"
            />
          </div>
          <span className="stardust-points">
            {numberWithCommas(stardusts || 0)}
          </span>
        </a>
      </Link>
      {/* <Link href="/buy/buy-stardust">
        <a className="at-addbtn">
          <i className="icon-add"></i>
        </a>
      </Link> */}
      <style jsx>{`
        .at-ratingbox {
          height: 40px;
          --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -1px rgba(0, 0, 0, 0.06);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
            var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          padding: 5px 8px;
          border-radius: 8px !important;
          background-color: #020410;
          max-width: 100px;
          
          span {
            margin-right: 10px;
          }

          .stardust-points {
            font-weight: 400;
            font-size: 16px;
            color: #a4b0be;
          }

          .at-addbtn {
            background: #dbcdfe;
          }
        }

        .image-box {
          width: 20px;
          height: 20px;
          position: relative;
          margin-right: 15px;
        }
      `}</style>
    </div>
  );
};
