interface iConfig {
  API_ENDPOINT: string;
  API_ENDPOINT_UPLOAD: string;
  SOCKET_ENDPOINT: string;
  BASE_URL: string;
  RECAPTCHA_KEY: string;
  WS_URL: string;
}

const config:iConfig = {
  API_ENDPOINT: process.env.NEXT_PUBLIC_API_ENDPOINT || "",
  API_ENDPOINT_UPLOAD: process.env.NEXT_PUBLIC_API_ENDPOINT_UPLOAD || "",
  SOCKET_ENDPOINT: process.env.NEXT_PUBLIC_SOCKET_ENDPOINT || "",
  BASE_URL: process.env.NEXT_PUBLIC_BASE_URL || "",
  RECAPTCHA_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_KEY || "",
  WS_URL: process.env.NEXT_PUBLIC_WS_URL || ""
};

export default config;
