import React from "react";
import Link from "next/link";
import { EVideoFilters } from "@enums/videoFilter.enum";
import useTranslation from "next-translate/useTranslation";

export const TrendingComponent = ({ active, setActive, setUserId, setCategories, reload }: any) => {
  const { t } = useTranslation("common");
  return (
    <React.Fragment>
      <div className="item">
        <Link href="/">
          <a
            onClick={() => {
              setActive(EVideoFilters.TRENDING);
              setUserId('');
              setCategories([]);
              reload()
            }}
            className={`${active && "active"}`}
          >
            <i className="icon-home" />
            <span>{t("banner.trending")}</span>
          </a>
        </Link>
      </div>
    </React.Fragment>
  );
};
